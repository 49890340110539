import React from "react"
import {fallWinter2024Stories} from './stories';
// import ScrollAnimation from 'react-animate-on-scroll';
import Link from "gatsby-plugin-transition-link/AniLink";

export default class MoreStories extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    var skip = this.props.slug;
    // console.log(skip)

    const list = fallWinter2024Stories.map(function(story){
      if (story.slug != skip) {

        var slug = "/fall-winter-2024/" + story.slug + "/";
        if (story.external === 'TRUE') {
          return (
            <li className="feature" key={story.slug}>
              <a
              className="link"
              href={story.url}
              target="_blank">
                <img src={story.thumbnail} alt="" className="link-image" />
                <span className="link-text">{story.title}</span>
              </a>
            </li>
          )
        } else {
          return (
            <li className="feature" key={story.slug}>
              <Link
              className="link"
              cover
              bg="#FF7900"
              direction="up"
              duration={.75}
              to={slug} >
                <img src={story.thumbnail} alt="" className="link-image" />
                <span className="link-text">{story.title}</span>
              </Link>
            </li>
          )
        }
      }
    })
    

    // console.log(stories);

    return (
      
        <section className="more-stories more-stories-fall-winter-2024">
          {/* <ScrollAnimation animateIn="fadeIn"> */}
          <div className="wrap">
            <h2 className="more-stories-title center">More Stories</h2>
            <ul className="related-story-list">
              {list}
            </ul>
          </div>
          {/* </ScrollAnimation> */}
        </section>
      
    );
  }
} 