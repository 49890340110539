import '../../styles/pages/fall-winter-2024/how-the-pandemic-changed-nursing-and-me.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';
import { Parallax, Background } from 'react-parallax';

import Hero from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-hero-1920x1080.jpg';
import socialBanner from "../../images/fall-winter-2024/social-banners/story5-social-1200x630.jpg";
import titelGraphic from "../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-title-380x245.svg";
import ctaBanner from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-cta-1920x515.jpg';

import nursingIMG1 from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-01-533x800.jpg';
import nursingIMG2 from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-01-800x533.jpg';
import nursingIMG3 from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-02-533x800.jpg';
import nursingIMG5 from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-03-800x533.jpg';
import nursingIMG6 from '../../images/fall-winter-2024/how-the-pandemic-changed-nursing-and-me/story5-01-3815x1355.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'How the Pandemic Changed Nursing … and Me';
    var pageDesc = 'During the darkest days of the COVID-19 pandemic, nursing faculty member Christine Kaford leaned into gratitude and hope. She teaches students to do the same.';
    var slug = 'how-the-pandemic-changed-nursing-and-me';
    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: nursingIMG1,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: nursingIMG2,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
      {
        src: nursingIMG3,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: nursingIMG5,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Cracking the Code on ‘Criminal Minds' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">During the darkest days of the COVID-19 pandemic, nursing faculty member Christine Kaford leaned into gratitude and hope. She teaches students to do the same.
</p>
            <span className="animated fadeInDown delay-1s byline">By Christine Kaford </span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

         

          <figure className='align-right large align-outside'>
      <img src={nursingIMG1} alt="" />
      <figcaption>Christine Kaford, CSUF assistant professor of nursing (Courtesy of Christine Kaford)</figcaption>
</figure>



<p>Looking back now, it feels like a blur. <a href="https://news.fullerton.edu/2020/04/nurses-battle-pandemic/" title="I worked on the front lines" target="_blank">I worked on the front lines</a> as an emergency department nurse when much of the world shut down in March 2020 due to the COVID-19 pandemic. I will never forget the moments of fear, the extensive conversations with nursing colleagues, the lack of planning and the sadness that rapidly spread.</p>



<p>It may have been extremely foolish for me and my colleagues to volunteer to try new practices, technology and more for the first time during a pandemic — but we didn&rsquo;t have time to consider the risks or benefits.</p>
<p>During the height of the pandemic, I taught clinical rotations and worked as many hours as possible. I did not do it to be a hero, but because both of my parents were immunocompromised and I could not be with them.</p>
<p>There were many instances when I, and other front-line workers, were not welcomed because we were exposed to the very disease that everyone was afraid to be around.</p>

<blockquote className='align-center large align-outside'>
  <p>We lost so much during the pandemic, but I choose to focus on what I have gained. <span className='left-quote'>”</span></p>
  <cite>Christine Kaford, assistant professor of nursing</cite>
</blockquote>

<p>I did not contract COVID-19 until 2021 when I was pregnant with my first child. I was working in triage and felt mild body aches. I was convinced I was tired because I was pregnant. Unfortunately, I tested positive for COVID-19.</p>

<figure className='align-left small align-outside'>
      <img src={nursingIMG3} alt="" />
      <figcaption>Christine Kaford and family (Courtesy of Christine Kaford)</figcaption>
</figure>

<p>At the time, my husband, a firefighter, was working the Dixie Fire in Northern California. I was alone, pregnant and extremely sick with a low oxygen saturation and an oral temperature of 102 to 103 F. I was barely able to take a couple steps from my bed to drink water or walk to the door to grab food sent by friends and family.</p>
<p>I remember crying a lot. I felt guilty that I got my unborn daughter sick.</p>



<p>My biggest takeaway from the experience is that we will never change until our love exceeds our pain. It was painful to watch the entire world suffer as a result of an unknown virus that quickly surfaced and changed our lives. Rather than focus on resentment or pain, I leaned into gratitude and hope.</p>
<p>I consciously remind myself that I matter. Just as I see my patients as real people who are deserving of care, I too am worthy of care. I remind my students that if nurses do not take care of themselves, their patients and families also will suffer.</p>
</div>
</article>
<Parallax
          bgImage={nursingIMG6}
          bgImageAlt=""
          strength={200}
          style={{margin: 0}}
          className='full-width'>
            <div className="caption">
            Christine Kaford (Matt Gush/CSUF)
            </div>
            <div className='height' />
</Parallax>

<article className='wrap' id="copy-wrap-no-margin">

<div className='wrap small' style={{marginTop: '2em'}}>


<figure className='align-left large align-outside'>
      <img src={nursingIMG5} alt="" />
      <figcaption>CSUF School of Nursing students with Christine Kaford, assistant professor of nursing (Matt Gush/CSUF)</figcaption>
</figure>


<p>Following the pandemic, I believe nursing has widened the lens to our quality of life, the true meaning of a service occupation, alternative pathways for self-care, improvements in mental health, the importance of community and roles beyond patient care. We are always evolving, moving forward to create a better life for our patients and ourselves.</p>
<p>Today&rsquo;s nursing education embraces such technologies and innovations as simulation, asynchronous and hybrid classrooms, interactive case studies, and virtual reality. We are beyond the traditional textbook and multiple-choice questions, which is a promising path to ensure students are equipped to step into the field with the foundational clinical judgment and skills required to practice safe, quality care.</p>


<p>As an educator, I am fueled by my desire to advocate for new nurses. I was a new nurse once; I know and will always remember how it felt.</p>

<blockquote className='align-right large align-outside'>
  <p>I hope that students never lose sight of the ‘why’ that brought them to our School of Nursing. <span className='left-quote'>”</span></p>
  <cite>Christine Kaford, assistant professor of nursing</cite>
</blockquote>

<p>My approach to education is rooted in active listening and taking time to understand the aspirations and interests of those who desire a future career in health care. I hope that students never lose sight of the &lsquo;why&rsquo; that brought them to our <a href="https://nursing.fullerton.edu/" title="School of Nursing" target="_blank">School of Nursing</a>.</p>
<p>My research interests have transitioned to the care of my students. I have had the opportunity to watch the students act with resilience and flexibility. They inspired me to begin a study that explores students&rsquo; lived experience in nursing education during the pandemic.</p>



<p>We lost so much during the pandemic, but I choose to focus on what I have gained: A deep respect for my profession and colleagues, and a happy and healthy daughter named Karisa, who is safe and well. <span className="end-mark" /></p>

<figure className='align-wide'>
      <img src={nursingIMG2} alt="" />
      <figcaption>Students gain hands-on experiences in the Nursing Simulation Center (Matt Gush/CSUF)</figcaption>
</figure>

<p><em>Christine Kaford is an assistant professor in Cal State Fullerton&rsquo;s School of Nursing, which is celebrating its 50th anniversary of preparing students to serve in Orange County&rsquo;s health care workforce. She teaches such courses as Critical Care Clinical Capstone and Nursing Capstone Theory for prelicensure nurses.</em></p>


    


          
          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the School of Nursing</span><br />
              <a href="https://give.fullerton.edu/titanmag-nursing" target='_blank' rel='noreferrer'>give.fullerton.edu/titanmag-nursing</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 